import React, { Component } from "react";
import PropTypes from "prop-types";
import { conformToMask } from "react-text-mask";

import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";
import PopupModal from "Components/Common/PopupModal/PopupModal";

import STRINGS from "Constants/Strings";
import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import { QRCodeSVG } from "qrcode.react";
import {
  ERROR_CODE,
  HTTP_STATUS,
  S_APPLICANT_ID,
} from "Communication/Constants";

import { isScanningAllowed } from "Utils/CommonUtilities";
import getUserCheckList from "Utils/AboutYouUtilities";

import { InstntSignupProvider } from "@instnt/instnt-react-js";
import {
  IDVERIFICATION_FAILED_CODE,
  ATTEMPTS,
  IDEVERIFICATION_AUTH,
  MAX_ATTEMPTS,
} from "../../Constants/APIConstants";
import IDSelection from "./IdSelection";
import ScanIdFront from "./ScanIdFront";
import ScanIdBack from "./ScanIdBack";
import Selfie from "./Selfie";
import Review from "./Review";

import "./AboutYou.scss";
import {
  countryAndProvinceIsValid,
  isNewMember,
  memberHasExpiredId,
} from "../../Utils/LogicUtilities";
import {
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  S_INSTNT_FINGER_PRINT,
  S_INSTNT_TXN_ID,
} from "../../Communication/Constants";

const AboutYouParts = {
  IDSELECTION: "idSelection",
  SCANIDFRONT: "scanIdFront",
  SCANIDBACK: "scanIdBack",
  SELFIE: "selfie",
  REVIEW: "review",
};

class AboutYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePart: AboutYouParts.REVIEW,
      idName: null,
      idType: null,
      idFront: null,
      idBack: null,
      selfie: props.formData?.selfieImage,
      retrieveCount: ATTEMPTS,
      checkInList: IDVERIFICATION_FAILED_CODE.INITIAL,
      formData: props.formData,
      showModal: false,
      showWelcome: false,
      error: false,
      description: "",
      isDesktop: true,
      doCapture: false,
      captureResult: null,
      documentSettings: null,
      instnttxnid: "",
      fingerprint: "",
      showDataError: false,
      showInfoMessage: false,
      maxAttempts: 0,
    };
  }

  componentDidMount() {
    const {
      scanDocument,
      inSessionJointApplicant,
      applicantData,
      finInfo,
    } = this.props;
    // is scanning allowed function is used to check if the device is mobile or tablet
    console.log("inSessionJointApplicant", inSessionJointApplicant);
    if (
      isScanningAllowed() &&
      ((scanDocument && !sessionStorage.getItem(S_APPLICANT_ID)) ||
        inSessionJointApplicant)
    ) {
      // if the logged in device is not desktop then this flag is used show the scanning confirmation question.
      this.setState({ isDesktop: false });

      const { checkInList } = this.state;
      const nextActivePart = this.getNextActivePart(checkInList);
      if (inSessionJointApplicant?.applicantId) {
        this.setState({
          activePart: AboutYouParts.REVIEW,
          captureResult: null,
        });
      } else {
        this.setState({ activePart: nextActivePart, captureResult: null });
      }
    }
    const { isInEditFlow } = this.props;
    // if the flow is edit then user by default lands to review part.
    if (
      isInEditFlow ||
      (sessionStorage.getItem(S_APPLICANT_ID) && !inSessionJointApplicant)
    ) {
      console.log("here 2");
      this.setState({ activePart: AboutYouParts.REVIEW, captureResult: null });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      vaultProductList,
      getActiveProduct,
      returningMember,
      isInEditFlow,
      scanDocument,
      inSessionJointApplicant,
      applicantData,
      finInfo,
    } = this.props;
    console.log("componentDidUpdate", returningMember);
    if (
      finInfo?.name &&
      applicantData?.id &&
      !this.state.showDataError &&
      !countryAndProvinceIsValid(applicantData, finInfo)
    ) {
      this.setState({
        showDataError: true,
        description: STRINGS.ABOUTYOU.MESSAGE_ERROR_ADDRESS,
      });
    } else if (
      !this.state.showInfoMessage &&
      !inSessionJointApplicant &&
      memberHasExpiredId(applicantData)
    ) {
      this.setState({
        showInfoMessage: true,
        description: STRINGS.ABOUTYOU.MESSAGE_ERROR_ID_EXPIRED,
      });
    } else if (
      returningMember &&
      prevProps.returningMember !== returningMember &&
      !this.state.showInfoMessage &&
      !isInEditFlow
    ) {
      this.setState({
        showWelcome: true,
        description: STRINGS.WELCOME_BACK1,
        description2: STRINGS.WELCOME_BACK2,
      });
    }

    if (prevProps.vaultProductList !== vaultProductList) {
      const activeProduct = getActiveProduct(vaultProductList);
      if (activeProduct?.applicantId && !inSessionJointApplicant) {
        this.setReviewStep();
      }
    }

    if (prevProps.scanDocument !== scanDocument) {
      if (
        isScanningAllowed() &&
        ((scanDocument && !sessionStorage.getItem(S_APPLICANT_ID)) ||
          inSessionJointApplicant)
      ) {
        // is scanning allowed function is used to check if the device is mobile or tablet
        // if the logged in device is not desktop then this flag is used show the scanning confirmation question.
        this.setStateAfterUpdate({ isDesktop: false });

        const { checkInList } = this.state;
        const nextActivePart = this.getNextActivePart(checkInList);
        this.setStateAfterUpdate({
          activePart: nextActivePart,
          captureResult: null,
        });
      }
    }
  }

  setStateAfterUpdate = (state) => {
    this.setState(state);
  };

  setReviewStep = () => {
    this.setState({ activePart: AboutYouParts.REVIEW, captureResult: null });
  };

  setStateFromResponseData = (resState) => {
    this.setState(resState);
  };

  setFormData = (formData) => {
    const { postalCode } = formData;
    const postalCodeFormatted = postalCode
      ? conformToMask(postalCode, AppConstants.ABOUT_YOU.CA_POSTALMASK, {
          guide: false,
        }).conformedValue
      : postalCode;
    this.setState({
      formData: { ...formData, postalCode: postalCodeFormatted },
    });
  };

  checkAllDataSet = () => {
    const { idFront, idBack, idType, selfie, checkInList } = this.state;
    const {
      selectId,
      captureFrontId,
      captureBackId,
      captureSelfie,
    } = checkInList;

    console.log("checkAllDataSet", idType, captureBackId, idBack);
    if (
      !selectId &&
      !captureFrontId &&
      ((!captureBackId && idType === AppConstants.IDTypes.LICENSE.desc) ||
        idType === AppConstants.IDTypes.PASSPORT.desc) &&
      !captureSelfie &&
      idFront &&
      ((idBack && idType === AppConstants.IDTypes.LICENSE.desc) ||
        idType === AppConstants.IDTypes.PASSPORT.desc) &&
      selfie &&
      idType
    ) {
      this.verifyDocuments(idFront, idBack, selfie, idType);
    } else {
      const nextActivePart = this.getNextActivePart(checkInList);
      this.setState({ activePart: nextActivePart, captureResult: null });
    }
  };

  /**
   * @description returns which part should be active in about you from 4 parts.
   *              1. IDSelection, 2. Scan Front, 3. Scan Back, 4. Review.
   * @param {*} checkList
   * @value {
    profile: true,
    selectId: true,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: true,
    }
   */
  getNextActivePart = (checkList) => {
    console.log("getNextActivePart", checkList);
    const {
      selectId,
      captureFrontId,
      captureBackId,
      captureSelfie,
      profile,
    } = checkList;
    if (selectId) {
      const checkInList = {
        selectId: false,
        captureFrontId,
        captureBackId,
        captureSelfie,
        profile,
      };
      this.setState({ checkInList });
      if (process.env.REACT_APP_TEST) {
        this.setState({
          checkInList: {
            selectId: false,
            captureFrontId: false,
            captureBackId: false,
            captureSelfie: true,
            profile,
          },
          idFront: "image",
          idBack: null,
        });
      }
      return AboutYouParts.IDSELECTION;
    }
    if (captureFrontId) {
      const checkInList = {
        selectId,
        captureFrontId: false,
        captureBackId,
        captureSelfie,
        profile,
      };
      this.setState({ checkInList });
      return AboutYouParts.SCANIDFRONT;
    }
    if (captureBackId) {
      const checkInList = {
        selectId,
        captureFrontId,
        captureBackId: false,
        captureSelfie,
        profile,
      };
      this.setState({ checkInList });
      return AboutYouParts.SCANIDBACK;
    }
    if (captureSelfie) {
      const checkInList = {
        selectId,
        captureFrontId,
        captureBackId,
        captureSelfie: false,
        profile,
      };
      this.setState({ checkInList });
      return AboutYouParts.SELFIE;
    }
    if (profile) {
      return AboutYouParts.REVIEW;
    }
    return null;
  };

  clearData = (checkList) => {
    const {
      selectId,
      captureFrontId,
      captureBackId,
      captureSelfie,
    } = checkList;

    if (selectId) {
      this.setState({ idType: null });
    }
    if (captureFrontId) {
      this.setState({ idFront: null });
    }
    if (captureBackId) {
      this.setState({ idBack: null });
    }
    if (captureSelfie) {
      this.setState({ selfie: null });
    }
  };

  idSelectionSubmitHandler = (id, type) => {
    this.setState(
      {
        idName: id,
        idType: type,
      },
      () => {
        this.checkAllDataSet();
      }
    );
  };

  scanIdFrontSubmitHandler = (scannedIdFront) => {
    const { idType, checkInList } = this.state;
    if (idType === AppConstants.IDTypes.PASSPORT.desc) {
      this.getNextActivePart(checkInList);
    }
    this.setState(
      {
        idFront: scannedIdFront,
      },
      () => {
        this.checkAllDataSet();
      }
    );
  };

  scanIdBackSubmitHandler = (scannedIdBack) => {
    this.setState(
      {
        idBack: scannedIdBack,
      },
      () => {
        this.checkAllDataSet();
      }
    );
  };

  selfieSubmitHandler = (scannedSelfie) => {
    this.setState(
      {
        selfie: scannedSelfie,
      },
      () => {
        this.checkAllDataSet();
      }
    );
  };

  verifyDocuments = (idFront, idBack, scannedSelfie, documentType) => {
    const { verifyDoc } = this.props;
    const { instnttxnid, maxAttempts } = this.state;
    console.log("verifyDocuments", window.instnt);
    let verifyRequest = {
      instnttxnid,
      docType: documentType,
    };
    if (process.env.REACT_APP_TEST) {
      verifyRequest = {
        instnttxnid: process.env.REACT_APP_TEST,
        docType: documentType,
      };
    }
    verifyDoc(verifyRequest, (response) => {
      const { doSubmitDocVerify } = this.props;
      let docRequest = { instnttxnid };
      if (process.env.REACT_APP_TEST) {
        docRequest = { instnttxnid: process.env.REACT_APP_TEST };
      }
      doSubmitDocVerify(docRequest, (submitIDRes) => {
        console.log("doSubmitDocVerify", submitIDRes);
        this.setState({ maxAttempts: 1 });
        this.handleSubmitIDVerify(submitIDRes, response.data);
      });
    });
  };

  handleSubmitIDVerify = (response, token) => {
    console.log("handleSubmitIDVerify", response);
    const { instnttxnid, maxAttempts } = this.state;
    if (response?.status === HTTP_STATUS.OK || maxAttempts === MAX_ATTEMPTS) {
      this.setFormData(response.data);
      const { retrieveCount, checkInList } = this.state;
      const { authentication, matchSelfie } = response.data;
      let checkList = checkInList;
      if (retrieveCount - 1 > 0) {
        checkList = getUserCheckList(response.data);
        console.log("checkList", checkList);
        const count = retrieveCount - 1;
        this.clearData(checkList);
        this.setStateFromResponseData({
          checkInList: checkList,
          retrieveCount: count,
        });
      } else {
        this.setStateFromResponseData({
          checkInList: IDVERIFICATION_FAILED_CODE.FINAL,
        });
        checkList = IDVERIFICATION_FAILED_CODE.FINAL;
      }
      const nextActivePart = this.getNextActivePart(checkList);
      console.log("nextActivePart", nextActivePart);
      this.setStateFromResponseData({
        activePart: nextActivePart,
        captureResult: null,
      });
      if (nextActivePart !== AboutYouParts.REVIEW) {
        if (
          nextActivePart === AboutYouParts.IDSELECTION ||
          nextActivePart === AboutYouParts.SCANIDFRONT ||
          nextActivePart === AboutYouParts.SCANIDBACK ||
          nextActivePart === AboutYouParts.SELFIE
        ) {
          let errorMessage = authentication;
          if (errorMessage.startsWith(IDEVERIFICATION_AUTH.DOCUMENT_NOT_PASS)) {
            errorMessage = STRINGS.ABOUTYOU.GENERIC_ERROR_MESSAGE;
          } else if (
            authentication === IDEVERIFICATION_AUTH.VERIFIED &&
            matchSelfie
          ) {
            errorMessage = matchSelfie;
          }
          this.setStateFromResponseData({
            showModal: true,
            error: true,
            description: errorMessage,
          });
        } else {
          // continue with review even if it is in error
          this.setStateFromResponseData({
            activePart: AboutYouParts.REVIEW,
            captureResult: null,
          });
        }
      }
    } else if (
      response?.data?.code === ERROR_CODE.DATA_NOT_AVAILABLE ||
      response?.data?.code === ERROR_CODE.FAIL_ASSERTION ||
      response?.data?.code === ERROR_CODE.UNPROCESSABLE_ENTITY
    ) {
      const { doSubmitDocVerify } = this.props;
      let request = {
        instnttxnid,
      };
      if (process.env.REACT_APP_TEST) {
        request = {
          instnttxnid: process.env.REACT_APP_TEST,
        };
      }
      setTimeout(
        doSubmitDocVerify(request, (submitIDRes) => {
          this.setState({ maxAttempts: maxAttempts + 1 });
          this.handleSubmitIDVerify(submitIDRes, token);
        }),
        10000
      );
    }
  };

  handleDataError = () => {
    console.log("handleDataError");
    const { history } = this.props;
    history.push(ROUTES.VAULT_DASHBOARD);
  };
  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.ABOUTYOU);
  };

  onEventHandler = (event) => {
    console.log("Instnt event: ", event?.type);
    let fingerprint = sessionStorage.getItem(S_INSTNT_FINGER_PRINT);
    switch (event.type) {
      case "transaction.initiated":
        console.log("new instnttxnid", event.data.instnt.instnttxnid);
        sessionStorage.setItem(
          S_INSTNT_TXN_ID,
          process.env.REACT_APP_TEST
            ? process.env.REACT_APP_TEST
            : event.data.instnt.instnttxnid
        );
        this.setState({
          instnttxnid: process.env.REACT_APP_TEST
            ? process.env.REACT_APP_TEST
            : event.data.instnt.instnttxnid,
        });
        const fingerPrintTxt = document.getElementById("fingerprint_txt");
        if (fingerPrintTxt?.value) {
          fingerprint = fingerPrintTxt.value;
          sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint);
          this.setState({ fingerprint });
        } else if (event.data.instnt.fingerprint_txt) {
          fingerprint = event.data.instnt.fingerprint_txt;
          sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint);
          this.setState({ fingerprint });
        } else if (window.instnt?.fingerprint_txt) {
          fingerprint = window.instnt.fingerprint_txt;
          sessionStorage.setItem(S_INSTNT_FINGER_PRINT, fingerprint);
          this.setState({ fingerprint });
        } else if (fingerprint) {
          this.setState({ fingerprint });
        }
        break;
      case "document.captured":
        this.setState({
          documentSettings: event.data.documentSettings,
          selfieSettings: event.data.selfieSettings,
          captureResult: event.data.captureResult,
          doCapture: false,
        });
        break;
      case "document.capture-cancelled":
        this.setState({ doCapture: false });
        break;
      default:
        console.log("unhandled instnt event ", event?.type);
    }
  };

  doScan = (flag) => {
    this.setState({ doCapture: flag });
  };

  render() {
    const {
      activePart,
      idName,
      idType,
      idFront,
      idBack,
      selfie,
      formData,
      showModal,
      error,
      description,
      description2,
      isDesktop,
      showWelcome,
      doCapture,
      captureResult,
      instnttxnid,
      fingerprint,
      showDataError,
      showInfoMessage,
    } = this.state;
    const {
      getDataFromServer,
      continuehandler,
      getActiveProduct,
      sessionCompletedProducts,
      finInfo,
      doCreateNewApplicant,
      vaultProductList,
      doUpdateProductToVault,
      doGetProductFromVault,
      history,
      doUpdateVaultProductList,
      processTemplate,
      doSearchMember,
    } = this.props;
    console.log("render", idType, activePart);
    console.log("instnttxnid", instnttxnid);
    let pageHeader = STRINGS.ABOUTYOU.SCREENTITLE;
    let aboutYou = null;
    switch (activePart) {
      case AboutYouParts.IDSELECTION:
        aboutYou = (
          <IDSelection
            idSelectionHandler={this.idSelectionSubmitHandler}
            vaultProductList={vaultProductList}
            getActiveProduct={getActiveProduct}
            doUpdateProductToVault={doUpdateProductToVault}
            history={history}
            doUpdateVaultProductList={doUpdateVaultProductList}
          />
        );
        break;
      case AboutYouParts.SCANIDFRONT:
        aboutYou = (
          <ScanIdFront
            idType={idType}
            idFront={idFront}
            scanIdFrontHandler={this.scanIdFrontSubmitHandler}
            doCapture={doCapture}
            doScan={this.doScan}
            capturedImage={
              captureResult?.result ? captureResult.result : idFront
            }
          />
        );
        break;
      case AboutYouParts.SCANIDBACK:
        aboutYou = (
          <ScanIdBack
            idBack={idBack}
            scanIdBackHandler={this.scanIdBackSubmitHandler}
            idType={idType}
            doCapture={doCapture}
            doScan={this.doScan}
            capturedImage={
              captureResult?.result ? captureResult.result : idBack
            }
          />
        );
        break;
      case AboutYouParts.SELFIE:
        aboutYou = (
          <Selfie
            selfieHandler={this.selfieSubmitHandler}
            selfie={selfie}
            idType={idType}
            doCapture={doCapture}
            doScan={this.doScan}
            capturedImage={
              captureResult?.result ? captureResult.result : selfie
            }
          />
        );
        break;
      case AboutYouParts.REVIEW:
        if (formData) {
          aboutYou = (
            <Review
              fName={formData.firstName}
              mName={formData.middleName}
              lName={formData.surName}
              dOB={formData.dateOfBirth}
              address={formData.address}
              addressLine2={formData.addressLine2}
              addressLine3={formData.addressLine3}
              city={formData.city}
              province={formData.state}
              postalCode={formData.postalCode}
              documentNumber={formData.documentNumber}
              expirationDate={formData.expirationDate}
              issueDate={formData.issueDate}
              issuerCode={formData.issuerCode}
              addressState={formData.addressState}
              instntTxnId={instnttxnid}
              fingerprint={fingerprint}
              selfieImage={selfie}
              idName={idName}
              isDesktop={isDesktop}
              getDataFromServer={getDataFromServer}
              continuehandler={continuehandler}
              getActiveProduct={getActiveProduct}
              idType={idType}
              sessionCompletedProducts={sessionCompletedProducts}
              vaultProductList={vaultProductList}
              doUpdateProductToVault={doUpdateProductToVault}
              doGetProductFromVault={doGetProductFromVault}
              processTemplate={processTemplate}
              doSearchMember={doSearchMember}
            />
          );
        } else {
          aboutYou = (
            <Review
              instntTxnId={instnttxnid}
              fingerprint={fingerprint}
              isDesktop={isDesktop}
              idName={idName}
              getDataFromServer={getDataFromServer}
              continuehandler={continuehandler}
              getActiveProduct={getActiveProduct}
              sessionCompletedProducts={sessionCompletedProducts}
              doCreateNewApplicant={doCreateNewApplicant}
              vaultProductList={vaultProductList}
              doUpdateProductToVault={doUpdateProductToVault}
              doGetProductFromVault={doGetProductFromVault}
              processTemplate={processTemplate}
              doSearchMember={doSearchMember}
            />
          );
        }
        pageHeader = STRINGS.ABOUTYOU.PARTFIVE.TITLE;
        break;
      default:
        break;
    }
    const { steps, activeStepID } = this.props;
    console.log(steps, activeStepID);
    console.log("isDesktop", isScanningAllowed());
    const commonRender = (
      <div className="[ about-you-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{pageHeader}</div>
        <InstntSignupProvider
          formKey={process.env.REACT_APP_INSTNT_KEY}
          onEvent={this.onEventHandler}
          serviceURL={process.env.REACT_APP_INSTNT_URL}
          idmetrics_version={process.env.REACT_APP_METRICS_VERSION}
        >
          {aboutYou}
        </InstntSignupProvider>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={
              error
                ? AppConstants.MODALTYPE.FAILURE
                : AppConstants.MODALTYPE.SUCCESS
            }
            title={error ? description : AppConstants.MODALTYPE.SUCCESS}
            description=""
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showWelcome && (
          <PopupModal
            type={AppConstants.MODALTYPE.WELCOME_BACK}
            description={description}
            description2={description2}
            showModal={showWelcome}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showDataError && (
          <PopupModal
            type={AppConstants.MODALTYPE.FAILURE}
            description={description}
            description2={description2}
            showModal={showDataError}
            popupBtnClick={this.handleDataError}
            closeBtnClick={this.handleDataError}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showInfoMessage && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            description={description}
            description2={description2}
            showModal={showInfoMessage}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={commonRender}
          securityText={null}
          infoType="welcomeBack"
          memberCreation
          handleBack={this.handleBack}
        />
      </>
    );
  }
}

AboutYou.propTypes = {
  verifyDoc: PropTypes.func,
  formData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  isInEditFlow: PropTypes.bool,
  doSubmitDocVerify: PropTypes.func,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  scanDocument: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  getActiveProduct: PropTypes.func,
  finInfo: PropTypes.oneOfType([PropTypes.object]),
  returningMember: PropTypes.bool,
  sessionCompletedProducts: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]),
  applicantData: PropTypes.oneOfType([PropTypes.object]),
};

AboutYou.defaultProps = {
  history: {},
  verifyDoc: () => {},
  formData: null,
  isInEditFlow: false,
  doSubmitDocVerify: () => {},
  steps: [],
  activeStepID: -1,
  vaultProductList: [],
  getActiveProduct: () => {},
  finInfo: {},
  returningMember: false,
  applicantData: null,
};

export default AboutYou;
